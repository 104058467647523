<template>
	<div>
		<div class="app-container">
			<el-form :model="dataForm" ref="dataForm" label-width="130px" class="demo-dataForm">

			

				<el-form-item label="机构名称">
					{{dataForm.fullName}}
				</el-form-item>
				<el-form-item label="所属行业">
					{{dataForm.industryTypeName}}

				</el-form-item>
				<el-form-item label="联系人姓名">
					<div style="display: flex;align-items: center;"> {{dataForm.linkName}}
						<div @click="visible = true" class="myBtn">更换联系人</div>
					</div>

				</el-form-item>
				<el-form-item label="联系人手机号">
					{{dataForm.linkTel}}
				</el-form-item>


				<el-form-item label="注册时间">
					{{dataForm.createTime}}
				</el-form-item>



			</el-form>
			<el-dialog title="更换联系人" :visible.sync="visible" width="1200px" :close-on-click-modal="false">
				<el-container style="padding: 1px;">
					<el-aside style="border: 1px solid #eeeeee;" width="535px">
						<OrgTree @refreshDataList="getDataList"></OrgTree>
					</el-aside>
					<el-main>
						<div class="mod-user">
							<el-form style="display: flex;justify-content: space-between;" :inline="true"
								:model="dataForm" @keyup.enter.native="getDataList()">

								<el-form-item style="margin-right: 0px;">
									<el-input v-model="searchContent" style="width: 400px;" class="input-with-select"
										placeholder="输入关键词查询">
										<el-select v-model="select" slot="prepend" placeholder="请选择">
											<el-option label="姓名" value="1"></el-option>
											<el-option label="手机号" value="2"></el-option>
										</el-select>
										<el-button slot="append" @click="getDataList()"
											icon="el-icon-search"></el-button>
									</el-input>
								</el-form-item>
							</el-form>
							<el-table :data="dataList" v-loading="dataListLoading"
								@selection-change="selectionChangeHandle" style="width: 100%;">

								<!-- <el-table-column type="selection"  header-align="center"
									align="center" width="50">
								</el-table-column> -->
								<el-table-column width="50" align="center">
									<template slot-scope="scope">
										<el-radio :label="scope.row.user" v-model="radioUser">{{ '' }}</el-radio>
									</template>
								</el-table-column>
								<el-table-column header-align="center" align="center" label="姓名">
									<template slot-scope="scope">
										<div style="padding: 11px;">{{scope.row.user.name}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="user.phone" header-align="center" align="center" label="手机号">
								</el-table-column>


							</el-table>
							<el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
								:current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
								:total="totalPage" layout="total, sizes, prev, pager, next, jumper">
							</el-pagination>
							<!-- 弹窗, 新增 / 修改 -->
							<AddAccount v-if="addVisible" ref="addAccount" @refreshDataList="getDeptList"></AddAccount>
							<EidtAccount v-if="addOrUpdateVisible&&dataListSelections.length!=0"
								:myData='dataListSelections[0]' ref="addOrUpdate" @refreshDataList="getDataList">
							</EidtAccount>
						</div>
					</el-main>
				</el-container>




				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="save">保存</el-button>
					<el-button @click="visible = false">取消</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>
<style scoped>
	.el-select {
		width: 100px;
	}

	.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}
</style>
<script>
	import axios from "axios";
	import moment from "moment";
	import {
		getImgUrl
	} from "@/utils";
	import qs from "qs";
	import Qs from 'qs'
	import OrgTree from './tree/tree'
	export default {
		data() {
			return {

				visible: false,

				dataForm: {


					industryType: "",

					fullName: "",
					linkName: '',
					linkTel: '',
					status: 1,
					pid: '0'
				},

				select: '1',
				searchContent: '',
				radioUser: '',
				dataList: [],
				pageIndex: 1,
				pageSize: 10,
				totalPage: 0,
				chooseDept: '',
				dataListLoading: false,
				dataListSelections: [],
				addOrUpdateVisible: false,
				addVisible: false,
			};
		},
		components: {
			OrgTree,

		},
		computed: {
			deptList: {
				get() {
					return this.$store.state.common.deptList
				},
				set(val) {
					this.$store.commit('common/updateDeptList', val)
				}
			},

		},
		async mounted() {


			await this.init(JSON.parse(sessionStorage.userInfo).deptId);
			//this.getRoleList()
			
				
			 
		},

		methods: {
		
			
			async save() {
				if (this.radioUser == '') {
					this.$message.error('请选择联系人')
					return
				}

				this.dataForm.simpleName = this.form.fullName
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl(
						this.dataForm.id ? "/dept/update" :
						"/dept/addDept"
					),
					method: "post",
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
					data: this.dataForm,
				});
				console.log(res);
				if (res.status) {
					this.$message.success(res.msg);
					this.$router.go(-1)

				}
			},
			async init(id) {

				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("/dept/info"),
					method: "post",
					data: {
						stringParam1: id
					},
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				if (res.status) {
					this.dataForm = res.data.dept;

					this.dataForm.createTime = moment(new Date(this.dataForm.createTime)).format(
						"YYYY-MM-DD HH:mm:ss"
					)
					this.dataListLoading = false;
				}

			},
			getRoleList() {
				let deptId = JSON.parse(sessionStorage.getItem("userInfo")).deptId
				let obj = {
					'userId': this.$cookie.get('userId'),
					pageNo: 1,
					pageSize: 99,
					deptId: deptId
				}

				this.$httpAes({
					url: this.$httpAes.adornUrl('role/page'),
					method: 'post',
					data: obj,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				}).then(({
					data
				}) => {
					console.log('role/page')
						console.log(data.data)
					this.roleList = data.data
					this.getDeptList()
				})
			},
			// 获取部门列表
			getDeptList(data) {
				console.log('123123getDeptList')
				let user = JSON.parse(sessionStorage.getItem("userInfo"))
				// 				let obj = {
				// 					'num'	: '1',
				// 'pid'	:'',
				// 'pids':'',	
				// 'simpleName'	:'江苏省教师培训中心',
				// 'fullName':'江苏省教师培训中心'
				// 				}
				// this.$httpAes({
				// 					url: this.$httpAes.adornUrl('dept/add'),
				// 					method: 'post',
				// 					headers: {
				// 						'Content-Type': 'application/x-www-form-urlencoded',
				// 			
				// 					},
				// 						data: Qs.stringify(obj)
				// 				}).then(({
				// 					data
				// 				}) => {
				// 					console.log(data.data)
				// 				})
				let obj ={
						stringParam1:'',
						stringParam2: '',
						stringParam3: user.deptId,
						stringParam4: '',
						intParam1: 1,
						pageNo: 1,
						pageSize: 99999
					}
				console.log(obj)
				this.$httpAes({
					url: this.$httpAes.adornUrl('dept/list'),
					method: 'post',
					data: obj,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				}).then(({
					data
				}) => {
					if (user.deptId != null) {
						let obj = {
							id: user.deptId,
							fullName: user.deptName,
							simpleName: user.deptName,
							num: '1'
						}
						data.data.unshift(obj)
					}
					this.deptList = data.data
					this.getDataList()
				})
			},
			// 获取数据列表
			getDataList(data) {
				let user = JSON.parse(sessionStorage.getItem("userInfo"))
				let name = ""
				let mobile = ""
				if (this.select == "1") {
					name = this.searchContent
				} else if (this.select == "2") {
					mobile = this.searchContent
				}
				let deptId = ""
				if (data) {
					deptId = data.id
					this.chooseDept = data.id
				} else if (user.deptId != null) {

					deptId = user.deptId
				}

				let obj = {

					stringParam5: this.pageIndex,
					stringParam4: deptId,
					stringParam6: this.pageSize,
					stringParam1: name,
					stringParam2: mobile
				}
				this.dataListLoading = true
				this.$httpAes({
					url: this.$httpAes.adornUrl('user/backUserList?pageSize=' + this.pageSize + '&pageNo=' + this
						.pageIndex +
						'&deptId=' + deptId + '&keyId=6ff9ee297ae8402d9109'),

					method: 'post',
					data: obj,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				}).then(({
					data
				}) => {
					if (data.status) {
						var dataList = data.data
						var roleList = this.roleList

						var deptList2 = this.deptList

						for (var i = 0; i < dataList.length; i++) {
							// var roleObj = roleList.find(function(x) {
							// 	return x.id == dataList[i].roleId
							// })
							// 							if (roleObj != undefined) {
							// 								dataList[i].roleName = roleObj.name
							// 							} else {
							// 								dataList[i].roleName = '超级管理员'
							// 							}
							// var deptObj = deptList2.find(function(x) {
							// 	return x.id == dataList[i].user.deptId
							// })
							// if (deptObj != undefined) {
							// 	dataList[i].user.deptName = deptObj.fullName
							// } else {
							// 	dataList[i].user.deptName = ''
							// }

						}
						this.dataList = data.data;
						this.totalPage = data.totalCount
						// this.totalPage = data.page.totalCount
					} else {
						this.dataList = []
						this.totalPage = 0
					}
					this.dataListLoading = false
				})
			},
			// 获取数据列表
			getDataList2(data) {
				let user = JSON.parse(sessionStorage.getItem("userInfo"))
				let name = ""
				let mobile = ""
				if (this.select == "1") {
					name = this.searchContent
				} else if (this.select == "2") {
					mobile = this.searchContent
				}
				let deptId = ""
				if (data) {
					deptId = data.id
				} else {

					deptId = user.deptId
				}

				let obj = {
					'userId': this.$cookie.get('userId'),
					pageNo: this.pageIndex,
					deptId: this.chooseDept,
					pageSize: this.pageSize,
					name: name,
					mobile: mobile
				}
				this.dataListLoading = true
				this.$httpAes({
					url: this.$httpAes.adornUrl('user/backUserList?pageSize=' + this.pageSize + '&pageNo=' + this
						.pageIndex +
						'&pid=' + user.deptId + '&name=' + name + '&phone=' + mobile + '&deptId=' + this
						.chooseDept + '&keyId=6ff9ee297ae8402d9109'),
					method: 'post',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',

						'token': this.$cookie.get('token'),
					},
				}).then(({
					data
				}) => {
					if (data.status) {
						var dataList = data.data
						var roleList = this.roleList

						var deptList2 = this.deptList

						for (var i = 0; i < dataList.length; i++) {
							var roleObj = roleList.find(function(x) {
								return x.id == dataList[i].roleId
							})
							// 							if (roleObj != undefined) {
							// 								dataList[i].roleName = roleObj.name
							// 							} else {
							// 								dataList[i].roleName = '超级管理员'
							// 							}
							var deptObj = deptList2.find(function(x) {
								return x.id == dataList[i].user.deptId
							})
							if (deptObj != undefined) {
								dataList[i].user.deptName = deptObj.fullName
							} else {
								dataList[i].user.deptName = ''
							}

						}
						this.dataList = data.data;
						this.totalPage = data.totalCount
						// this.totalPage = data.page.totalCount
					} else {
						this.dataList = []
						this.totalPage = 0
					}
					this.dataListLoading = false
				})
			},
			checkSelectable(row, index) {
				if (row.user.id == JSON.parse(sessionStorage.userInfo).id) {
					return false;
				} else {
					return true;
				}
			},
			sizeChangeHandle(val) {
				this.pageSize = val
				this.pageIndex = 1
				this.getDataList()
			},
			// 当前页
			currentChangeHandle(val) {
				this.pageIndex = val
				this.getDataList()
			},
			// 多选
			selectionChangeHandle(val) {
				this.dataListSelections = val
			},



			getDate() {
				const date = new Date(),
					year = date.getFullYear(),
					month =
					date.getMonth() > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`,
					day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`,
					hh = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`,
					mm =
					date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
				return `${year}${month}${day}${hh}${mm}`;
			},



			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
		},
		watch: {
			cityInfo(val) {
				console.log(val);
				this.center = val.locationCity.label + val.locationCounty.label;
			},
		},
	};
</script>
<style scoped>
	.myBtn {
		cursor: pointer;
		width: 110px;
		height: 28px;
		line-height: 28px;
		text-align: center;
		border-radius: 2px;
		border: 1px solid #0476F5;
		margin-left: 16px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 14px;
		color: #0476F5;
	}

	.el-input__inner {
		width: 50%;
	}

	.el-input-number--medium {
		width: 50%;
		line-height: 34px;
	}

	.el-input-number .el-input__inner {
		-webkit-appearance: none;
		padding-left: 50px;
		padding-right: 50px;
		text-align: left !important;
	}

	.bm-view {
		width: 500px;
		height: 300px;
	}

	.app-container {
		margin-top: 30px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #0476F5;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 147px;
		height: 147px;
		line-height: 147px;
		text-align: center;
	}

	.avatar {
		height: 146px;
		display: block;
	}
</style>